import '../App.css';
import { Link } from 'react-router-dom';

function Header() {
  const isMobileOrTablet = window.matchMedia("(max-width: 1024px)").matches;
  const imgSrc = isMobileOrTablet === true ? "https://beautiful-essentials-cloud-front-bucket.s3.amazonaws.com/landing_page/images/header_mobile.jpg"
    : "https://beautiful-essentials-cloud-front-bucket.s3.amazonaws.com/landing_page/images/header_desktop_background.jpg"
  return (
    <div className={`header layout-row center-${isMobileOrTablet === true ? 'center' : 'left'}`}>
      <Link to="/" className={`layout-row center-${isMobileOrTablet === true ? 'center' : 'left'}`}>
        <img alt='' src={imgSrc}/>
        <div className='header-logo layout-row center-left lg-only'>
          <img className='header-logo-img' alt='' src="https://beautiful-essentials-cloud-front-bucket.s3.amazonaws.com/landing_page/images/header_desktop_logo.svg"/>
        </div>
      </Link>
    </div>
  );
}

export default Header;
