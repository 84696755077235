import { Link } from 'react-router-dom';
import '../App.css';

function PrivacyPage() {
  return (
    <div className="privacy layout-column center-center">
      <div className="privacy-terms">
        <Link to='https://beautiful-essentials-cloud-front-bucket.s3.amazonaws.com/landing_page/documents/Privacy+Policy+17.11.24.pdf' target="_blank" className="pdf-link pink bold layout-row">Download pdf</Link>
        {/* <div className="main-title">
        <h1 className="header-2 bold">BEAUTIFUL ESSENTIALS PRIVACY POLICY ("PRIVACY POLICY")</h1>
      </div> */}
        <div className="page-body">
          <div className="section table-contents">
            <ol className="toc-list" role="list">
              <li>
                <a href="#title-a">
                  <span className="title regular" style={{ marginBottom: 8 }}>PRIVACY POLICY
                    <span className="leaders" aria-hidden="true"></span>
                  </span> <span data-href="#title-a" className="page regular"><span className="visually-hidden">Page&nbsp;</span>2</span>
                </a>
                <ol role="list">
                  <li>
                    <a href="#section-a">
                      <span className="title regular">Scope and Overview
                        <span className="leaders" aria-hidden="true"></span>
                      </span> <span data-href="#section-a" className="page regular"><span className="visually-hidden">Page&nbsp;</span>2</span>
                    </a>
                  </li>
                  <li>
                    <a href="#section-b">
                      <span className="title regular">Personal Information We Collect
                        <span className="leaders" aria-hidden="true"></span>
                      </span> <span data-href="#section-b" className="page regular"><span className="visually-hidden">Page&nbsp;</span>3</span>
                    </a>
                  </li>
                  <li>
                    <a href="#section-c">
                      <span className="title regular">Important Information About Sharing Your Photomyne Materials
                        <span className="leaders" aria-hidden="true"></span>
                      </span> <span data-href="#section-c" className="page regular"><span className="visually-hidden">Page&nbsp;</span>7</span>
                    </a>
                  </li>
                  <li>
                    <a href="#section-d">
                      <span className="title regular">Categories of Sources from Which We Collect Personal Information
                        <span className="leaders" aria-hidden="true"></span>
                      </span> <span data-href="#section-d" className="page regular"><span className="visually-hidden">Page&nbsp;</span>8</span>
                    </a>
                  </li>
                  <li>
                    <a href="#section-e">
                      <span className="title regular">Business and Commercial Purposes of Collecting Personal Information From Consumers
                        <span className="leaders" aria-hidden="true"></span>
                      </span> <span data-href="#section-e" className="page regular"><span className="visually-hidden">Page&nbsp;</span>8</span>
                    </a>
                  </li>
                  <li>
                    <a href="#section-f">
                      <span className="title regular">Categories Of Personal Information We Have Sold Or Shared, Including In The Preceding 12 Months; Categories of Third Parties To Whom Such Information Was Sold Or Shared
                        <span className="leaders" aria-hidden="true"></span>
                      </span> <span data-href="#section-f" className="page regular"><span className="visually-hidden">Page&nbsp;</span>10</span>
                    </a>
                  </li>
                  <li>
                    <a href="#section-g">
                      <span className="title regular">Disclosure of Personal Information to Our Service Providers
                        <span className="leaders" aria-hidden="true"></span>
                      </span> <span data-href="#section-g" className="page regular"><span className="visually-hidden">Page&nbsp;</span>13</span>
                    </a>
                  </li>
                  <li>
                    <a href="#section-h">
                      <span className="title regular">Our Use of Consumers' Sensitive Personal Information
                        <span className="leaders" aria-hidden="true"></span>
                      </span> <span data-href="#section-h" className="page regular"><span className="visually-hidden">Page&nbsp;</span>13</span>
                    </a>
                  </li>
                  <li>
                    <a href="#section-i">
                      <span className="title regular">Personal Information Concerning Children Under 16
                        <span className="leaders" aria-hidden="true"></span>
                      </span> <span data-href="#section-i" className="page regular"><span className="visually-hidden">Page&nbsp;</span>15</span>
                    </a>
                  </li>
                </ol>
              </li>
              <li>
                <a href="#title-b">
                  <span className="title regular">COMMUNICATIONS AND NOTIFICATIONS
                    <span className="leaders" aria-hidden="true"></span>
                  </span> <span data-href="#title-b" className="page regular"><span className="visually-hidden">Page&nbsp;</span>16</span>
                </a>
              </li>
              <li>
                <a href="#cookies">
                  <span className="title regular">COOKIES AND SIMILAR TECHNOLOGIES
                    <span className="leaders" aria-hidden="true"></span>
                  </span> <span data-href="#cookies" className="page regular"><span className="visually-hidden">Page&nbsp;</span>16</span>
                </a>
              </li>
              <li>
                <a href="#personal-info">
                  <span className="title regular">HOW LONG YOUR PERSONAL INFORMATION WILL BE KEPT
                    <span className="leaders" aria-hidden="true"></span>
                  </span> <span data-href="#personal-info" className="page regular"><span className="visually-hidden">Page&nbsp;</span>17</span>
                </a>
              </li>
              <li>
                <a href="#territoriality">
                  <span className="title regular">TERRITORIALITY
                    <span className="leaders" aria-hidden="true"></span>
                  </span> <span data-href="#territoriality" className="page regular"><span className="visually-hidden">Page&nbsp;</span>18</span>
                </a>
              </li>
              <li>
                <a href="#title-f">
                  <span className="title regular">U.S. CONSUMERS PRIVACY RIGHTS
                    <span className="leaders" aria-hidden="true"></span>
                  </span> <span data-href="#title-f" className="page regular"><span className="visually-hidden">Page&nbsp;</span>18</span>
                </a>
              </li>
              <li>
                <a href="#title-g">
                  <span className="title regular" style={{ marginBottom: 8 }}>NOTICE TO EUROPEAN UNION AND U.K. CONSUMERS
                    <span className="leaders" aria-hidden="true"></span>
                  </span> <span data-href="#title-g" className="page regular"><span className="visually-hidden">Page&nbsp;</span>21</span>
                </a>
                <ol role="list">
                  <li>
                    <a href="#section-ga">
                      <span className="title regular">Lawful Basis for Processing Personal Information
                        <span className="leaders" aria-hidden="true"></span>
                      </span> <span data-href="#section-ga" className="page regular"><span className="visually-hidden">Page&nbsp;</span>21</span>
                    </a>
                  </li>
                  <li>
                    <a href="#section-gb">
                      <span className="title regular">European Residents' Rights and Choices
                        <span className="leaders" aria-hidden="true"></span>
                      </span> <span data-href="#section-gb" className="page regular"><span className="visually-hidden">Page&nbsp;</span>23</span>
                    </a>
                  </li>
                  <li>
                    <a href="#section-gc">
                      <span className="title regular">Cross-Border Data Transfers
                        <span className="leaders" aria-hidden="true"></span>
                      </span> <span data-href="#section-gc" className="page regular"><span className="visually-hidden">Page&nbsp;</span>27</span>
                    </a>
                  </li>
                </ol>
              </li>
              <li>
                <a href="#title-h">
                  <span className="title regular" style={{ marginBottom: 8 }}>NOTICE TO CANADIAN CONSUMERS
                    <span className="leaders" aria-hidden="true"></span>
                  </span> <span data-href="#title-h" className="page regular"><span className="visually-hidden">Page&nbsp;</span>27</span>
                </a>
                <ol role="list">
                  <li>
                    <a href="#section-ha">
                      <span className="title regular">Canadian Residents' Rights and Choices
                        <span className="leaders" aria-hidden="true"></span>
                      </span> <span data-href="#section-ha" className="page regular"><span className="visually-hidden">Page&nbsp;</span>28</span>
                    </a>
                  </li>
                </ol>
              </li>
              <li>
                <a href="#title-i">
                  <span className="title regular">NOTICE TO RESIDENTS OF JURISDICTIONS OTHER THAN THE UNITED STATES, CANADA, THE UNITED KINGDOM AND THE EUROPEAN UNION
                    <span className="leaders" aria-hidden="true"></span>
                  </span> <span data-href="#title-i" className="page regular"><span className="visually-hidden">Page&nbsp;</span>28</span>
                </a>
              </li>
              <li>
                <a href="#biometric-data-policy">
                  <span className="title regular">BIOMETRIC DATA POLICY
                    <span className="leaders" aria-hidden="true"></span>
                  </span> <span data-href="#biometric-data-policy" className="page regular"><span className="visually-hidden">Page&nbsp;</span>29</span>
                </a>
              </li>
              <li>
                <a href="#privacy-rights">
                  <span className="title regular">MANAGING YOUR PRIVACY RIGHTS AND CHOICES
                    <span className="leaders" aria-hidden="true"></span>
                  </span> <span data-href="#privacy-rights" className="page regular"><span className="visually-hidden">Page&nbsp;</span>31</span>
                </a>
              </li>
              <li>
                <a href="#title-l">
                  <span className="title regular">GLOBAL OPT OUT PREFERENCE SIGNAL
                    <span className="leaders" aria-hidden="true"></span>
                  </span> <span data-href="#title-l" className="page regular"><span className="visually-hidden">Page&nbsp;</span>34</span>
                </a>
              </li>
              <li>
                <a href="#title-m">
                  <span className="title regular">KEEPING YOUR PERSONAL INFORMATION SECURE
                    <span className="leaders" aria-hidden="true"></span>
                  </span> <span data-href="#title-m" className="page regular"><span className="visually-hidden">Page&nbsp;</span>34</span>
                </a>
              </li>
              <li>
                <a href="#title-n">
                  <span className="title regular">LINKS TO THIRD PARTY WEBSITES
                    <span className="leaders" aria-hidden="true"></span>
                  </span> <span data-href="#title-n" className="page regular"><span className="visually-hidden">Page&nbsp;</span>34</span>
                </a>
              </li>
              <li>
                <a href="#title-o">
                  <span className="title regular">CHANGES TO OUR PRIVACY POLICY
                    <span className="leaders" aria-hidden="true"></span>
                  </span> <span data-href="#title-o" className="page regular"><span className="visually-hidden">Page&nbsp;</span>35</span>
                </a>
              </li>
              <li>
                <a href="#contact-info">
                  <span className="title regular">CONTACT INFORMATION
                    <span className="leaders" aria-hidden="true"></span>
                  </span> <span data-href="#contact-info" className="page regular"><span className="visually-hidden">Page&nbsp;</span>35</span>
                </a>
              </li>
            </ol>
          </div>
          <div className="section" id="title-a">
            <p className="header-2 titles bold">PRIVACY POLICY</p>
            <ol>
              <li className="section" id="section-a">
                <p className="header-2 titles bold">Scope and Overview</p>
                <p>
                  Photomyne Ltd. and its subsidiaries and affiliates, including but not limited to Photomyne, Inc. (“Photomyne”, “we”, “us”), is dedicated to safeguarding and honoring your privacy.
                  This Privacy Policy outlines our practices of collecting and handling Personal Information that we obtain from various sources such as customers, potential customers, website visitors, suppliers, vendors, service providers, contractors, consultants, and other third parties.
                </p>
                <p>
                  This Privacy Policy applies to Personal Information collected on or via Beautiful Essentials' websites and microsites that we own and manage, via our social media accounts, and via any of our applications (the “BE Apps”, “Apps” and collectively, the “Platform”).
                </p>
                <p className='bold'>
                  If you interact with us using the Photomyne Website, or via the Photomyne applications (“Photomyne Apps”), please see our Photomyne <Link to='https://photomyne.com/privacy-policy' target='_blank'>Privacy Policy</Link>.
                </p>
                <p>
                  “Personal Information” used in this Privacy Policy refers to information that identifies, relates to, or is linked or reasonably linkable to an identified or identifiable individual. “Services” used in this Privacy Policy refers to content, features, software, related technology and services as defined the <a href="/terms-of-use">Terms of Use</a>.
                </p>
                <p>
                  The data controller or business in charge of operating the https://beautiful-essentials.app/ website and of any of the services provided through the BE Apps is Photomyne.
                  For our contact details please see the <a href="#contact-info">“Contact Information”</a> section below.
                </p>
                <p>
                  BY USING OR ACCESSING OUR PLATFORM IN ANY MANNER, YOU ACKNOWLEDGE THAT YOU ACCEPT THE PRACTICES AND POLICIES OUTLINED IN THIS POLICY, AND YOU HEREBY CONSENT THAT WE WILL COLLECT, USE, AND SHARE YOUR INFORMATION IN THE FOLLOWING WAYS UNLESS OTHERWISE REQUIRED BY LAW.
                  IF YOU DO NOT AGREE WITH THIS PRIVACY POLICY, PLEASE DO NOT INTERACT WITH AND NAVIGATE AWAY FROM THE PLATFORM.
                  IF YOU USE OR INTERACT WITH THE PLATFORM ON BEHALF OF ANOTHER INDIVIDUAL (SUCH AS YOUR MINOR CHILD) OR ENTITY (SUCH AS YOUR EMPLOYER), YOU REPRESENT THAT YOU ARE AUTHORIZED BY SUCH INDIVIDUAL OR ENTITY TO ACCEPT THIS PRIVACY POLICY ON SUCH INDIVIDUAL'S OR ENTITY'S BEHALF.
                  YOU REPRESENT AND WARRANT THAT THE INFORMATION THAT YOU ARE INPUTTING IS ACCURATE.
                </p>
                <p>
                  YOU ARE NOT LEGALLY REQUIRED TO PROVIDE US WITH PERSONAL INFORMATION, HOWEVER, USE OF THE SERVICES REQUIRES THAT YOU PROVIDE PERSONAL INFORMATION.
                  IF YOU CHOOSE TO WITHHOLD ANY PERSONAL INFORMATION REQUIRED IN RESPECT THEREOF, IT WILL NOT BE POSSIBLE FOR YOU TO USE THE SERVICES.
                  ALL PERSONAL INFORMATION WILL ONLY BE PROCESSED IN ACCORDANCE WITH THIS POLICY.
                </p>
              </li>
              <li className="section" id="section-b">
                <p className="header-2 titles bold">Personal Information We Collect</p>
                <p>
                  We collect Personal Information for the purposes of providing our products and services to you and for related business purposes disclosed in this Privacy Policy.
                  The types of Personal Information relating to you that we may collect, and the purposes for which we process this data, depends on the nature of your interaction with us.
                </p>
                <p>
                  In particular, we may collect, and have collected within the last twelve (12) months, the following categories of Personal Information from visitors to, and customers of our Platform:
                  <table className="parties">
                    <thead>
                      <tr>
                        <td>Categories of Data Collected</td>
                        <td>Examples of Datapoints Collected</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          A. Identifiers
                        </td>
                        <td>
                          <p>
                            Your real name, alias, postal address, email address, account name, unique personal identifier, online identifier, Internet Protocol address, IDFA, AAID, GAID, or other similar identifiers, and Materials you scan or upload, as well as other image inputs for our generative AI features - if you provide such information.
                          </p>
                          <p>
                            Materials may include:
                            <ul>
                              <li>Photos, slides, videos, paintings, images, negatives, documents and/or any other information you scan with the Apps or upload to the Apps.</li>
                              <li>Any details that you provide relating to the aforementioned materials such as the names of the people, locations and any other text/information you choose to provide.</li>
                              <li>Voice recordings and audio information, which is a feature that may be available to you in some of the services.</li>
                              <li>Birthday and family relationship details and other family tree information or information regarding family members.</li>
                            </ul>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          B. Personal information described in the California Consumer Records Act, Section 1798.80, subdivision (e).
                        </td>
                        <td>
                          We collect your name, address and telephone number.
                          If you choose to make a purchase from us, our service provider(s) will collect your credit card number; however, we will only collect the last four digits of your credit card number to show you which credit card you used to make the purchase.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          C. Protected classification characteristics under state or federal law.
                        </td>
                        <td>
                          Age, gender, gender identity, or gender expression.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          D. Commercial Information
                        </td>
                        <td>
                          Records of services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies, user communications, app events, feedback and recommendations.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          E. Biometric Data
                        </td>
                        <td>
                          We may process biometric data in connection with providing certain features with your express consent.
                          For more information, please see our <a href="#biometric-data-policy">Biometric Data Policy</a>.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          F. Internet or other similar network activity
                        </td>
                        <td>
                          Information on a user's interaction with a website or application, any actions you make on the Platform (such as when Materials were scanned, edited, shared or deleted);
                          interaction with hyperlinks, widgets and other features of the Platform, and device information (such as operating system and its versions, device model, operating system settings, unique device identifiers, crash data, software and hardware attributes), cookies and similar technologies.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          G. Geolocation data
                        </td>
                        <td>
                          Approximate geolocation data, time zone, zip code, information about your mobile service provider and language settings.
                          We do not collect precise geolocation.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          H. Sensory Data
                        </td>
                        <td>
                          Audio (only if you choose to use the ability to add sound recordings to the Platform).
                        </td>
                      </tr>
                      <tr>
                        <td>
                          I. Professional of Employment-Related Information
                        </td>
                        <td>
                          We may receive information as to which company you are employed or affiliated with.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          K. Inferences drawn from other Personal Information.
                        </td>
                        <td>
                          Information to create a profile about a consumer reflecting a consumer's preferences or trends.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          L. Sensitive or Special Category Personal Information
                        </td>
                        <td>
                          <ul>
                            <li>Account log-in.</li>
                            <li>If you choose to make a purchase from us, our service provider(s) will collect your credit card number;
                              however, we will only collect the last four digits of your credit card number to show you which credit card you used to make the purchase.</li>
                            <li>We may process biometric data in connection with providing certain features with your express consent.
                              For more information, please see our <a href="#biometric-data-policy">Biometric Data Policy</a>.</li>
                            <li>Personal Information on a child under 16.
                              We do not knowingly collect any Personal Information <u>from</u> visitors or users of our Platform who are under the age of 16.
                              To the extent that you upload images or other data featuring or about a current child under 16, we may collect this information, with your express consent and representation that you have the right to upload this information.</li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </p>
                <p className="header-2 titles bold">NOTE:</p>
                <p>
                  You may choose to use the Apps to scan documents, which may include other categories of information than identified above or other types of data, including Sensitive Personal Information.
                  We have no way to prevent you from doing so and if you decide to scan such documents.
                  The documents you scan using the Apps will be backed up on our servers on Amazon Web Services (“AWS”).
                  If you later delete the documents you scanned, they will be removed from our back up in a few months.
                  If these documents include information in any of the categories above, we have no way to know that this is the case.
                  We urge you NOT to scan documents which might include Sensitive Personal Information.
                </p>
                <p>
                  <b>Connecting to “Google Photos”:</b> Our Platform enables you to upload your Materials to your Google Photos account from within the Photomyne apps.
                  To start uploading photos to Google Photos, you have to configure access to your Google Photos account first.
                  Once you have connected to your Google Photos account and in order to upload your Materials, you will be asked to give Photomyne permission to add photos to your Google Photos account.
                  This action does not grant Photomyne access to your existing photos at Google Photos other than the Materials uploaded through Photomyne.
                  This is following a security protocol set by Google.
                  To disconnect Photomyne from Google Photos, go to the 'Settings' window in the Photomyne Apps and click the 'Disconnect from Google Photos' button.
                  Once the authorization is granted, we will also receive your full name, Gmail address and Gmail account picture which will be presented in the 'Settings' window in the Photomyne Apps to inform you whether or not you are connected to your Google Photos account.
                  Furthermore, if you're an Android user your Gmail address might be used as part of the 'Google Sign In' process should you decide to sign in or re- connect to your Google Photos account this way.
                  We will not use this information for any other purpose.
                </p>
              </li>
              <li className="section" id="section-c">
                <p className="header-2 titles bold">Important Information About Sharing Your Materials</p>
                <p>
                  The Apps offer various ways to share the Materials you scanned or uploaded.
                  Once you have shared your Materials and other Personal Information or information with others (via various channels available through the Apps), or submitted Materials for public use, it may be re-shared by others.
                  You are solely responsible for your decision to share your Materials and other Personal Information with others and/or to make your Materials and other Personal Information and information visible by others.
                  <span className="bold">Please keep in mind that you must be legally authorized to share with others the information that does not belong to you, such as information pertaining to another person.
                    If you are posting information of a known child under 16, you must be their parent or legal guardian.</span>
                </p>
                <p>
                  Your Materials, other Personal Information, and any other information that you voluntarily post on the Platform, such as through your profile, may become visible by the public on the Platform, if you choose to share such data using one of the methods described herein.
                </p>
                <p className="bold">
                  Here is an explanation of what it means to share Materials, in terms of your privacy and your produced content.
                </p>
                <p>
                  <u>Posting or submitting Materials for public display:</u>
                </p>
                <p>
                  Posting or submitting Materials for public display through the Photomyne Apps means that you consent to and give Photomyne permission to publish such Materials (including for editorial and advertising purposes).
                  The Materials will be public and visible to people online, outside of the Photomyne Apps.
                  Such Materials could also find their way to strangers on the web.
                  We have no control over how other people may interact with your published Materials (taking a screenshot, downloading the photo or other Materials, etc.).
                  Please keep that in mind before posting or submitting such Materials or any other content for public use.
                </p>
                <p>
                  <u>Sharing Materials through the Photomyne Apps (generated web link):</u>
                </p>
                <p>
                  Choosing to share Materials through the Photomyne apps' sharing option generates a unique web link which you can then pass on to your contacts and recipients.
                  Anyone with that web link can have access to the Materials you shared.
                  We have no control over how other people you shared your Materials with via the web link may interact with your content (taking a screenshot, downloading the photo or video, etc. or passing the web link on to others).
                  This means it's possible that your shared Materials could find their way to strangers on the web.
                  To minimize the risk of unauthorized use of your content, the generated web link will work for a maximum of 1 year (365 days), after which it will become invalid.
                </p>
                <p>
                  <u>Sharing Materials by using the Photomyne Share App:</u>
                </p>
                <p>
                  Choosing to share Materials through the Photomyne Share App (“Photomyne Share”) generates a unique code which you can then pass on to your contacts and recipients.
                  Anyone with that code can have access to the Materials you shared through Photomyne Share.
                  We have no control over how other people you shared your Materials with via Photomyne Share may interact with your content (taking a screenshot, downloading the photo or video, etc. or passing the code on to others).
                  This means it's possible that your shared Materials could find their way to strangers on the web.
                  To minimize the risk of unauthorized use of your content, the generated unique code will work for a maximum of 24 hours, after which it will become invalid.
                </p>
              </li>
              <li className="section" id="section-d">
                <p className="header-2 titles bold">Categories of Sources from Which We Collect Personal Information</p>
                <p>
                  We obtain the categories of Personal Information listed above from the following categories of sources:
                  <ul>
                    <li>
                      Directly from you or your representatives. For example, information that our customers provide to us related to a transaction or Material that our customers scan with the Apps or upload to the Apps.
                    </li>
                    <li>
                      Operating systems and platforms. For example, we collect information about your device's interaction with our website and apps.
                    </li>
                    <li>
                      Our vendors. For example, if you interact with Apple, Google, or any other platform or social media account while using our services, we may receive information from your account, including your name, profile picture, email address, and any information defined as public pursuant to the policies of such platforms.
                    </li>
                  </ul>
                </p>
              </li>
              <li className="section" id="section-e">
                <p className="header-2 titles bold">Business and Commercial Purposes of Collecting Personal Information From Consumers</p>
                <p>
                  We collect Personal Information to:
                  <ul>
                    <li>
                      To provide our Services, including generative AI technology and enable you to carry out the main purpose for which you requested our Services, for example to scan, save, share and safeguard your scans and to retrieve your content should anything happen;
                    </li>
                    <li>
                      To improve the user experience, such as providing you with information, support, products or services that you request from us or which may be of interest to you;
                    </li>
                    <li>
                      To improve our Platform and to help resolve any problems when you send email or other communication to us;
                    </li>
                    <li>
                      To understand the usage trends and preferences of our users, to improve the way the Platform components work and look, and to create new features and functionality;
                    </li>
                    <li>
                      To enable internal record keeping;
                    </li>
                    <li>
                      To process your orders and transactions;
                    </li>
                    <li>
                      To fulfill and manage purchases, orders, payments, and returns/exchanges;
                    </li>
                    <li>
                      To communicate to you and your authorized representatives the information on goods, services, experiences, and other special offers we believe may be of genuine interest to you;
                    </li>
                    <li>
                      To market, advertise and assess the effectiveness of our events, promotional campaigns, publications, and services;
                    </li>
                    <li>
                      To market and promote the Photomyne brand or the Beautiful Essentials brand;
                    </li>
                    <li>
                      To operate, troubleshoot, and improve our offering of services;
                    </li>
                    <li>
                      To understand how people use our services, including by generating and analyzing statistics;
                    </li>
                    <li>
                      To operate and improve online services;
                    </li>
                    <li>
                      To personalize online and offline content;
                    </li>
                    <li>
                      To maintain safety in our offices and combat illegal activities;
                    </li>
                    <li>
                      As necessary to ensure network administration and security, such as infrastructure monitoring, identity and credential management, authentication, and access control;
                    </li>
                    <li>
                      For internal administration, data analysis, billing, and detecting, preventing, and responding to actual or potential fraud, illegal activities, or intellectual property infringement;
                    </li>
                    <li>
                      To comply with legal obligations and to enforce our rights;
                    </li>
                    <li>
                      To host, store and otherwise process information needed for business continuity and disaster recovery;
                    </li>
                    <li>
                      To evaluate or conduct a merger, reorganization, dissolution, or other sale or transfer of some or all of our assets, or similar corporate transaction or proceeding, in which Personal Information held by us is among the assets transferred.
                    </li>
                  </ul>
                </p>
              </li>
              <li className="section" id="section-f">
                <p className="header-2 titles bold">Categories Of Personal Information We Have Sold Or Shared, Including In The Preceding 12 Months; Categories of Third Parties To Whom Such Information Was Sold Or Shared</p>
                <p>
                  We do not sell your Personal Information in the conventional sense (i.e., for money).
                  Like many companies, however, we use services that help deliver interest-based ads to you and may transfer Personal Information to business partners for their use.
                  Making information such as online identifiers or browsing activity available to these companies may be considered a “sale” or “sharing” under California law. According to California consumer privacy law, a “sale” includes disclosing, making available or communicating Personal Information to a third party (except our service providers or contractors) in exchange for monetary or other valuable consideration.
                  “Sharing” includes disclosing, making available or communicating Personal Information to a third party for cross-context behavioral advertising, whether or not for any monetary or valuable consideration.
                </p>
                <p>
                  Currently, and in the preceding 12 months, we have sold or shared to third parties the following categories of Personal Information:
                  <table className="parties">
                    <thead>
                      <tr>
                        <td>Categories of Personal Information Sold or Shared</td>
                        <td>Categories Of Third Parties To Whom The Information Was Sold Or Shared</td>
                        <td>Purposes of Sale or Sharing</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          Identifiers
                        </td>
                        <td>
                          Analytics and advertising companies (like ad servers, advertising agencies, technology vendors, providers of sponsored content, and others), such as Google (including Google Analytics, Google AdMob and Google AdSense), Microsoft, Paddle, TikTok, X (Twitter), Pinterest, Facebook, Bing.
                        </td>
                        <td>
                          Analytics and support, specifically, to track the performance of our websites and applications, to understand our target audiences better and tailor our content strategies accordingly.
                          To generate personalized advertisements or content and to perform ad measurement services on our behalf.
                          Our advertising partners build a profile of your interests using your interactions on our Platform together with Personal Information you have provided to them elsewhere.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Commercial Information
                        </td>
                        <td>
                          Analytics and advertising companies (like ad servers, advertising agencies, technology vendors, providers of sponsored content, and others), such as Google (including Google Analytics, Google AdMob and Google AdSense),Microsoft, Paddle, TikTok, X (Twitter), Pinterest, Facebook, Bing.
                        </td>
                        <td>
                          Analytics and personalized user experience including advertising to identify areas for improvement and make informed decisions to enhance our digital presence, to analyze traffic sources and measure the effectiveness of marketing channels, allowing us to allocate resources effectively.
                          Furthermore, we can assess the performance of our content, identify popular pages, and create more valuable content for our audience.
                          To generate personalized advertisements or content and to perform ad measurement services on our behalf.
                          Our advertising partners build a profile of your interests using your interactions on our Platform together with Personal Information you have provided to them elsewhere.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Internet or other electronic network activity information
                        </td>
                        <td>
                          Analytics and advertising companies (like ad servers, advertising agencies, technology vendors, providers of sponsored content, and others), such as Google (including Google Analytics, Google AdMob and Google AdSense), Microsoft, Paddle, TikTok, X (Twitter), Pinterest, Facebook, Bing.
                        </td>
                        <td>
                          Analytics and personalized user experience including advertising to identify areas for improvement and make informed decisions to enhance our digital presence, to analyze traffic sources and measure the effectiveness of marketing channels, allowing us to allocate resources effectively.
                          Furthermore, we can assess the performance of our content, identify popular pages, and create more valuable content for our audience.
                          To generate personalized advertisements or content and to perform ad measurement services on our behalf.
                          Our advertising partners build a profile of your interests using your interactions on our Platform together with Personal Information you have provided to them elsewhere.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Geolocation data
                        </td>
                        <td>
                          Analytics and advertising companies (like ad servers, advertising agencies, technology vendors, providers of sponsored content, and others), such as Google (including Google Analytics, Google AdMob and Google AdSense), Microsoft, Paddle, TikTok, X (Twitter), Pinterest, Facebook, Bing.
                        </td>
                        <td>
                          Analytics and personalized user experience including advertising to identify areas for improvement and make informed decisions to enhance our digital presence, to analyze traffic sources and measure the effectiveness of marketing channels, allowing us to allocate resources effectively.
                          Furthermore, we can assess the performance of our content, identify popular pages, and create more valuable content for our audience.
                          To generate personalized advertisements or content and to perform ad measurement services on our behalf.
                          Our advertising partners build a profile of your interests using your interactions on our Platform together with Personal Information you have provided to them elsewhere.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </p>
                <p>
                  We do not knowingly sell or share the Personal Information of known minors under 16 years of age, and have no actual knowledge of such sale or sharing occurring.
                </p>
                <p>
                  Consumers have the right to opt out of the sale and sharing of Personal Information.
                  To exercise this right, please follow the instructions on our <a onclick="javascript:openAxeptioCookies()" className="manage-cookies">Cookie Management Tool</a>.
                </p>
                <p>
                  We may aggregate data we acquire about our Platform visitors.
                  If we aggregate such data and provide it to external parties, our customers' Personal Information will not be attached to or included in such aggregated data.
                  In an ongoing effort to better understand and serve the users of the Platform, We often conduct research on our aggregated customer demographics, interests and behavior.
                  We may share this aggregate data with our affiliates, agents and business partners.
                  We may also disclose aggregated user statistics in order to describe our services to current and prospective business partners, and to other third parties for other lawful purposes.
                </p>
              </li>
              <li className="section" id="section-g">
                <p className="header-2 titles bold">Disclosure of Personal Information to Our Service Providers</p>
                <p>
                  In order to fulfill the purposes identified in Section E above, we partner with service providers.
                  For example, we may partner with electronic communication partners to communicate with you and to provide our online services to you.
                  We may disclose the aforementioned categories of Personal Information to a service provider for a disclosed business purpose.
                  When we disclose Personal Information for a business purpose, we enter a contract that describes the purpose and requires the service provider to both keep that Personal Information confidential and not use it for any purpose except performing the contract.
                  Our agreements with our service providers shall impose similar terms with respect to information practices as set forth in this Privacy Policy and will be not less protective than those imposed on Us.
                </p>
                <p>
                  <u>Information Shared with Service Providers:</u>
                </p>
                <p>
                  Service providers that work with us use your IP address to help us with server problem diagnosis and to administer the Services.
                  An IP address is a numeric code that identifies your device on a network, or in this case, the Internet.
                  Your IP address is also used to gather broad demographic information.
                  We may also perform IP lookups through service providers to determine which domain you are coming from (i.e. aol.com, yourcompany.com) to more accurately gauge our users' demographics.
                </p>
                <p>
                  We may share Personal Information such as Identifiers, Commercial Information and Internet or other similar network activity with service providers for support and diagnostic purposes or in order to provide us with reports and insights on how to optimize our promotional campaigns and to find people on different platforms that might be interested in our Platform.
                </p>
                <p>
                  We use service providers for customer support and communications email services, including live-chat services, who will process Personal Information that you submit when communicating with our support via email or when using chat features on the Platform, together with Commercial Information and Internet or other similar network activity data.
                </p>
                <p>
                  Our servers that are used to store your Personal Information are owned and hosted by Amazon Web Services, Inc., a subsidiary of Amazon.com (their privacy policies and practices may be found at http://aws.amazon.com/privacy/), which are located in the US and Europe, including Ireland.
                  Our servers that are used to store deidentified or aggregated Personal Information are owned and hosted by Google Cloud Platform (GCP) (their Privacy Policy and practices may be found at https://policies.google.com/privacy).
                  If you do not agree to such transfer, please stop using the Apps and Services and follow the actions described under the <a href="#forget-steps">“Steps to exercise your Right to be Forgotten”</a>.
                  If you have any questions, please contact us in accordance with the <a href="#contact-info">“Contact Information”</a> Section below.
                </p>
                <p>
                  Below you can find a list of the categories of our service providers by whom Personal Information may be processed or transferred to and for what purposes, including in the last twelve (12) months.
                </p>
                <table className="parties">
                  <thead>
                    <tr>
                      <td>Categories of Recipient/Services</td>
                      <td>Business Purpose</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        Infrastructure and storage
                      </td>
                      <td>
                        <ul>
                          <li>
                            Secured storage of all Personal Information when you use the Services.
                          </li>
                          <li>
                            Backup of all data.
                          </li>
                          <li>
                            Data management.
                          </li>
                          <li>
                            Technical support.
                          </li>
                          <li>
                            Authentication and authorization services.
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Customer service and communications
                      </td>
                      <td>
                        <ul>
                          <li>
                            To provide customer service.
                          </li>
                          <li>
                            To reach out to you with our newsletter, surveys and notifications.
                          </li>
                          <li>
                            To obtain customer feedback.
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Financial services (credit card processor)
                      </td>
                      <td>
                        <ul>
                          <li>
                            To collect and process payments for subscription to the Platform.
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Analytics and Marketing
                      </td>
                      <td>
                        <ul>
                          <li>
                            To understand the usage trends and preferences of our users and generate statistical reports.
                          </li>
                          <li>
                            To improve and personalize user experience.
                          </li>
                          <li>
                            To create new features and functionality.
                          </li>
                          <li>
                            To provide us with reports and insights on how to optimize our promotional campaigns and to find people on different platforms that might be interested in our Services.
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Features and AI service providers
                      </td>
                      <td>
                        <ul>
                          <li>
                            To make available additional features on the Services.
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </li>
              <li className="section" id="section-h">
                <p className="header-2 titles bold">Our Use of Consumers' Sensitive Personal Information</p>
                <p>
                  Under privacy laws, certain types of Personal Information are considered “sensitive” and require additional data privacy rights and obligations.
                  As noted above, we collect account login, certain credit card information, and biometric data, which may be considered Sensitive Personal Information under applicable privacy laws.
                  Where required by law, we will obtain your consent before processing Sensitive Personal Information.
                  We will also only use your Sensitive Personal Information for limited purposes, such as to perform the services or provide the goods reasonably expected by you, prevent, detect, and investigate security incidents that compromise the availability, integrity, or confidentiality of stored or transmitted Personal Information, resist malicious, deceptive, fraudulent, or illegal actions directed at our business and prosecute those responsible, to ensure the physical safety of natural persons, verify or maintain the quality or safety of our products, services, or devices, and otherwise use the information in a manner that does not involve inferring characteristics about you.
                  If you have any questions about our handling of Sensitive Personal Information or to withdraw your consent where consent is required (i.e., for biometric data), please contact us at <Link className="regular" to='#'
                    onClick={(e) => {
                      window.location.href = 'mailto:support@beautiful-essentials.app';
                      e.preventDefault();
                    }} target="_blank">support@beautiful-essentials.app</Link>.
                </p>
                <p>
                  Please note that we encourage you to not provide us more information or other Personal Information that may be considered Sensitive Personal Information under privacy laws, including when you use the Apps to scan documents.
                  We have no way to prevent you from doing so and if you decide to scan such documents, we do not use them or analyze them.
                  If these documents include Sensitive Personal Information, we have no way to know that this is the case.
                </p>
                <p>
                  In the event that we retain service providers or contractors to process Sensitive Personal Information on our behalf, we will do so pursuant to a data sharing agreement as required by applicable law.
                </p>
              </li>
              <li className="section" id="section-i">
                <p className="header-2 titles bold">Personal Information Concerning Children Under 16</p>
                <p>
                  As a policy, no person under the age of 18 is permitted to interact with the Platform.
                </p>
                <p>
                  We do not knowingly collect any Personal Information from visitors or users of our Platform who are under the age of 16.
                  If you are under 16, please do not give us any Personal Information.
                  We encourage parents and legal guardians to monitor their children's Internet usage and to help enforce our Policy by instructing their children to never provide Personal Information through the Internet without their permission.
                  If you have reason to believe that a child under the age of 16 has provided Personal Information to us, please contact us using the <a href="#contact-info">CONTACT INFORMATION</a> below, and we will endeavor to delete that information from our databases.
                </p>
                <p>
                  To the extent that you upload images or other data <u>featuring or about</u> a current child under 16, we may collect this information, with your express consent and representation that you have the right to upload this information.
                  You may not upload any images, video or audio files featuring children under 16 unless you are their parent or legal guardian.
                </p>
                <p>
                  We do not intentionally sell or share the Personal Information of consumers under 16 years of age, and do not have any actual knowledge of any such sale or sharing.
                </p>
              </li>
            </ol>
          </div>
          <div className="section" id="title-b">
            <p className="header-2 titles bold">COMMUNICATIONS AND NOTIFICATIONS</p>
            <p>
              Depending on your user device settings, we may send you push notifications to offer you special updates or marketing in respect of the Platform.
              You should be able to opt-out of these notifications at the device level.
              We may also send you newsletters and promotional communications, you may opt-out of this service at any time by clicking UNSUBSCRIBE at the bottom of each email or by submitting a request to <Link className="regular" to='#'
                onClick={(e) => {
                  window.location.href = 'mailto:support@beautiful-essentials.app';
                  e.preventDefault();
                }} target="_blank">support@beautiful-essentials.app</Link>.
            </p>
          </div>
          <div className="section" id="cookies">
            <p className="header-2 titles bold">COOKIES AND SIMILAR TECHNOLOGIES</p>
            <p>
              Cookies are small text files placed on your device to store data that can be recalled by a web server in the domain that placed the cookie.
              We use cookies and similar technologies for storing and honoring your preferences and settings when accessing and interacting with our websites, combating fraud, analyzing how our websites and applications perform, customizing advertisements, and fulfilling other legitimate purposes.
            </p>
            <p>
              Most browsers or devices may allow you to block cookies, but you may not be able to use some features within our website if you block them.
            </p>
            <p>
              When referring to cookies we include first- and third-party cookies, tracking pixels and plug-ins, including technologies from third party publishers and other tracing technologies.
            </p>
            <p>
              You can disable non-essential cookies and manage your cookie preferences any time through the “Cookie Management” widget, accessible by clicking on the cookie icon available consistently on our website.
              You can also set your browser not to accept cookies.
              However, please be aware that if you choose not to accept cookies or disable them, you may face limitation on functionality and a deteriorated user experience.
            </p>
            <p>
              For more detailed information about our cookies and the reason for using them, please our <a href="/cookie-policy" target="_blank">Cookie Policy</a>.
            </p>
          </div>
          <div className="section" id="personal-info">
            <p className="header-2 titles bold">HOW LONG YOUR PERSONAL INFORMATION WILL BE KEPT</p>
            <p>
              In all circumstances, Personal Information will be retained, as follows:
              <ul>
                <li>
                  Any biometric data used in connection with providing our features is retained for up to 30 days;
                </li>
                <li>
                  Personal Information will not be retained for longer than is necessary in relation to the purposes for which such Personal Information was collected or otherwise processed, including for the purpose of ensuring your Materials are saved and backed up for easy retrieval, should you need it (e.g. providing you with the Services); and
                </li>
                <li>
                  In the event you withdraw your consent and there are no more legitimate grounds for the processing, your Personal Information will be removed within the time period required by applicable laws.
                </li>
              </ul>
            </p>
            <p>
              Unless otherwise provided in this Policy, we will keep your Personal Information while you have an account with us or while we are providing services to you.
              Thereafter, we will keep your Personal Information for as long as is necessary:
              <ul>
                <li>
                  To respond to any questions, complaints or claims made by you or on your behalf.
                </li>
                <li>
                  To provide services as contracted for by you.
                </li>
                <li>
                  Please be aware that if you remove or delete data from your account, you will no longer see it in the Apps, but some backups of the data may remain in our archive servers on AWS and will be removed from our archive servers in a few months.
                </li>
                <li>
                  To comply with legal obligations and to enforce our rights.
                </li>
                <li>
                  To show that we treated you fairly.
                </li>
                <li>
                  To keep records required by law.
                </li>
              </ul>
            </p>
            <p>
              We will not retain your Personal Information for longer than necessary for the purposes set out in this Policy.
              Different retention periods apply for different types of Personal Information.
              When it is no longer necessary to retain your Personal Information, we will delete or anonymize it.
            </p>
          </div>
          <div className="section" id="territoriality">
            <p className="header-2 titles bold">TERRITORIALITY</p>
            <p>
              The server on which the Services are hosted and/or through which the Services are processed may be outside the country from which you access them and may be outside your country of residence.
              Most of the information is saved to the cloud for backup. We use service providers for live-chat and email services.
              Some of the uses of the Personal Information may involve the transfer of your Personal Information to our various service providers, established in countries around the world that may have different levels of privacy protection than your country.
              However, we take additional measures when information is transferred from the European Economic Area (EEA).
              This includes having standard clauses approved by the European Commission in our contracts with parties that receive information outside the EEA.
              We also rely on European Commission adequacy decisions about certain countries, as applicable, for data transfers to countries outside the EEA.
            </p>
            <p>
              We utilize Amazon Web Services (AWS) for our computer systems, which are based in the United States and in Ireland.
              We furthermore utilize Amazon Web Services (AWS) for disaster recovery solutions.
              If you reside in other jurisdictions and if you visit our Platform or subscribe to our services, we may collect and process your Personal Information in the United States, where data protection and privacy regulations may or may not be equal to the level of protection as in other parts of the world, such as in Canada, the United Kingdom and the European Union.
              BY VISITING OUR PLATFORM OR SUBSCRIBING TO OUR SERVICES, YOU UNEQUIVOCALLY AND UNAMBIGUOUSLY CONSENT TO THE COLLECTION AND PROCESSING IN THE UNITED STATES OF YOUR PERSONAL INFORMATION COLLECTED OR OBTAINED BY US THROUGH YOUR VOLUNTARY SUBMISSIONS, AND THAT UNITED STATES LAW GOVERNS ANY SUCH COLLECTION AND PROCESSING.
            </p>
          </div>
          <div className="section" id="title-f">
            <p className="header-2 titles bold">U.S. CONSUMERS PRIVACY RIGHTS</p>
            <p>
              Depending on your state of residence, you have certain rights with respect to your Personal Information that we collect and use. These may include:
            </p>
            <p>
              <i>Right to Know</i> - the right to know what Personal Information the business has collected about the consumer, including the categories of personal information, the categories of sources from which the Personal Information is collected, the business or commercial purpose for collecting, selling, or sharing Personal Information, the categories of third parties to whom the business discloses Personal Information, and the specific pieces of Personal Information the business has collected about the consumer.
            </p>
            <p>
              You may also have the right to request that we transfer information about you to a third party.
              Once we receive and confirm your verifiable consumer request, we will disclose to you:
              <ol>
                <li>
                  The categories of Personal Information we collected about you.
                </li>
                <li>
                  The categories of sources for the Personal Information we collected about you.
                </li>
                <li>
                  Our business or commercial purpose for collecting or selling that Personal Information.
                </li>
                <li>
                  The categories of third parties with whom we share that personal information.
                </li>
                <li>
                  The specific pieces of Personal Information we collected about you (also called a data portability request).
                </li>
                <li>
                  If we sold, shared or disclosed your Personal Information for a business purpose, two separate lists disclosing:
                  <ul>
                    <li>
                      sales, identifying the Personal Information categories that each category of recipient purchased; and
                    </li>
                    <li>
                      disclosures for a business purpose, identifying the Personal Information categories that each category of recipient obtained.
                    </li>
                  </ul>
                </li>
                <li>
                  If requested by you, we will provide you with a copy of the Personal Information you provided to us, in a structured, commonly used and machine-readable format and/or transmit that data to a third party.
                </li>
                <li>
                  You also have the right to request us to correct inaccurate information and to limit the use and disclosure of Sensitive Personal Information.
                </li>
              </ol>
            </p>
            <p>
              <i>Right to Delete</i> - the right to request that we delete any of your Personal Information that we collected from you and retained, subject to certain exceptions.
              Once we receive and confirm your verifiable consumer request, we will delete (and direct service providers and third parties who received your Personal Information to delete) your Personal Information from our records, unless an exception applies.
              We will ask you to delete the Apps from any or all devices, in order to complete the deletion.
            </p>
            <p>
              We may deny your deletion request if retaining the information is necessary for us or our service providers to:
              <ol>
                <li>
                  Complete the transaction for which we collected the Personal Information, provide a good or service that your employer requested, take actions reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform our contract with you.
                </li>
                <li>
                  Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities.
                </li>
                <li>
                  Debug products to identify and repair errors that impair existing intended functionality.
                </li>
                <li>
                  Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise another right provided for by law.
                </li>
                <li>
                  Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 seq.).
                </li>
                <li>
                  Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information's deletion may likely render impossible or seriously impair the research's achievement, if you previously provided informed consent.
                </li>
                <li>
                  Enable solely internal uses that are reasonably aligned with consumer expectations based on your or your employer's relationship with us.
                </li>
                <li>
                  Comply with a legal obligation.
                </li>
                <li>
                  Make other internal and lawful uses of that information that are compatible with the context in which you or your employer provided it.
                </li>
              </ol>
            </p>
            <p>
              <i>Right to Correct</i> - the right to request that we update any information about you that is inaccurate or incomplete.
              Once we receive and confirm your verifiable consumer request, we will update (and direct our service providers to update) your Personal Information in our records.
              You also have the right to require us to restrict processing of your Personal Information in certain circumstances, e.g. if you contest the accuracy of the Personal Information.
            </p>
            <p>
              <i>Right to Opt-Out of the Sale or Sharing of Personal Information</i> - the right to opt-out of the sale or sharing of their Personal Information by us.
              Once we receive and confirm your verifiable consumer request, we will no longer sell or share your Personal Information.
            </p>
            <p>
              <i>Right to Limit the Use or Disclosure of Sensitive Personal Information</i> - the right to request that we limit our use of your Sensitive Personal Information to that which is necessary to perform the services or provide the goods that you reasonably expect of us, with some narrowly tailored exceptions.
            </p>
            <p>
              <i>Right to Non-Discrimination</i> - We will not discriminate or retaliate against you for exercising any of your privacy rights.
              In that regard, we will not take the following actions in response to your exercising of your privacy rights, unless permitted by the applicable laws.
            </p>
          </div>
          <div className="section" id="title-g">
            <p className="header-2 titles bold">NOTICE TO EUROPEAN UNION AND U.K. CONSUMERS</p>
            <p>
              This section is intended to inform European Residents of their rights and to provide disclosures as required under the European Union's General Data Protection Regulation (“GDPR”) and the United Kingdom's GDPR.
              Photomyne is the data controller for the website you are currently accessing.
              For more information on how to contact us, please see section labeled <a href="#contact-info">“Contact Information”</a>.
            </p>
            <p>
              For information on what Personal Information we collect and for what purposes, please go to the section labeled <a href="#section-b">“Personal Information We Collect”</a> and <a href="#section-e">“Business and Commercial Purposes Of Collecting Personal Information From Consumers”</a> in our Privacy Policy.
            </p>
            <p>
              For information on what Personal Information we may disclose to third parties, please go to the sections labeled <a href="#section-g">“Disclosure of Personal Information to Our Service Providers”</a> and <a href="#section-f">“Categories of Personal Information We Have Sold Or Shared Including In The Preceding 12 Months”</a>.
            </p>
            <p>
              For more information on cookies, how we uses cookies, what types of cookies we use, and how to manage your cookies, please go to the section labeled <a href="#cookies">“Cookies and Similar Technologies”</a> and to our <a href="/cookie-policy" target="_blank">Cookie Policy</a>.
            </p>
            <ol>
              <li className="section" id="section-ga">
                <p className="header-2 titles bold">Lawful Basis for Processing Personal Information</p>
                <p>
                  We will only collect and process Personal Information about you where we have lawful basis. We rely on the following legal grounds:
                </p>
                <p>
                  <i>For the fulfilment of contractual obligations.</i> Mainly, in order to provide you with the services, information or support that you request from us.
                </p>
                <p>
                  <i>For the pursuit of our legitimate interests.</i> To the extent necessary, we may process your Personal Information beyond the performance of the actual services, in order to protect the legitimate interests of us or third parties.
                  This includes our commercial interests, including our interest in improving the Platform and protecting the security and integrity of the Platform and internal record keeping.
                </p>
                <p>
                  <i>Due to legal obligation.</i> We may be obligated to process some of your Personal Information to comply with applicable laws and regulations.
                </p>
                <p>
                  <i>Based on your consent.</i> We recognize that your Personal Information belongs to you, and you have the right to control how it is used.
                  Therefore, we rely on your freely given, informed, and explicit consent as the lawful basis for processing your Personal Information.
                </p>
                <p>
                  When you provide your consent, you are granting us permission to process your Personal Information for specific purposes that are set forth in our Privacy Policy and clearly explained to you at the time of data collection.
                  You have the right to withdraw your consent at any time, without affecting the lawfulness of processing based on consent before its withdrawal.
                  Here are some key points to understand about consent as the basis for processing Personal Information:
                  <ul>
                    <li>
                      <b>Voluntary Consent</b>: Your consent is entirely voluntary, and you are not obligated to provide it.
                      If you choose not to consent or withdraw your consent, it may limit our ability to provide certain services or engage with you in specific ways.
                    </li>
                    <li>
                      <b>Informed Consent</b>: We will provide you with clear and accessible information about the processing activities that require your consent.
                      This information will include the purposes of processing, the types of data involved, and the duration for which your consent is valid.
                    </li>
                    <li>
                      <b>No Pre-Ticked Boxes</b>: We will not use pre-ticked boxes or other default settings that assume your consent.
                      You will actively and affirmatively provide consent through a clear and unambiguous action, such as ticking a box, clicking a button, or signing a consent form.
                    </li>
                    <li>
                      <b>Withdrawal of Consent</b>: You have the right to withdraw your consent at any time by contacting us using the <a href="#contact-info">contact information</a> provided in this Privacy Policy.
                      Withdrawal of consent will not affect the lawfulness of processing based on consent before its withdrawal.
                    </li>
                  </ul>
                </p>
                <p>
                  Where you have given consent and to the extent that you have not subsequently objected to the use of your data within this context, we will use your Personal Information for advertising and marketing activities.
                  For further information please see the <a href="#cookies">“Cookies and Similar Technologies”</a> section of this Policy and our <a href="/cookie-policy" target="_blank">Cookie Policy</a>.
                </p>
                <p>
                  If you have any questions about the lawful basis upon which we collect and use your Personal Information, please contact our Data Protection Officer at support@beautiful-essentials.app.
                </p>
                <p>
                  We process your Personal Information only for specific and limited purposes, including the provision of the services that you request of us through the Platform.
                  We ask only for Personal Information that is adequate, relevant and not excessive for such purposes.
                  The specific purposes of the different types of Personal Information collected are listed the Section <a href="#section-e">“Business and Commercial Purposes of Collecting Personal Information from Consumers”</a>.
                </p>
              </li>
              <li className="section" id="section-gb">
                <p className="header-2 titles bold">European Residents' Rights and Choices</p>
                <p>
                  This section describes your privacy rights if you live in the European Union and how to exercise those rights.
                </p>
                <p>
                  <i>Right to Access</i> - EU residents have the right to request a copy of the information we hold about them.
                  Before we provide a substantive response to your request for information, we will verify your identity to make certain you are who you say you are.
                  We will respond within 30 days of receiving your written request.
                </p>
                <p>
                  We will not disclose in response to an access request your account password, or biometric identifiers.
                  We will, however, inform you or your representative with sufficient particularity if we have collected that type of information.
                </p>
                <p>
                  <i>Right to Erase (also known as the Right to Be Forgotten)</i> - You have the right to request that we delete any of your Personal Information that we collected from you and retained, subject to certain exceptions.
                  Once we receive and confirm your verifiable consumer request, we will delete (and direct our service providers to delete) your Personal Information from our records, permanently and completely erasing your Personal Information from our existing systems, except from archive or backup systems, or de-identifying the Personal Information such that it cannot reasonably be used to infer information about, or otherwise be linked to you. Under certain circumstances, we may be forced to object to your request to erase, such as when we are under a legal obligation to maintain your Personal Information.
                  If such circumstances apply to your request, we will advise you in writing in response to your request.
                  Please keep in mind that once you request to exercise your Right to be Forgotten with regard to your use of the Apps, any deleted content and/or information will not be retrievable.
                  Please also note that we are required by law to keep a record of your request and our response to it.
                </p>
                <p id="forget-steps">
                  <u>Steps to exercise your Right to be Forgotten:</u>
                </p>
                <ol>
                  <li>
                    Contact us using the Apps: Open the app's side-menu and select Help & Support.
                    Next, select Email us; or by emailing us at <Link className="regular" to='#'
                      onClick={(e) => {
                        window.location.href = 'mailto:support@beautiful-essentials.app';
                        e.preventDefault();
                      }} target="_blank">support@beautiful-essentials.app</Link>
                  </li>
                  <li>
                    In your message, please mention “Right to be Forgotten” in the message's subject field.
                  </li>
                  <li>
                    We will then confirm the receipt of your message, and may ask follow up questions for verification.
                  </li>
                  <li>
                    We will ask you to delete the Apps from any or all devices, in order to begin the process and complete the erasure.
                  </li>
                  <li>
                    We will delete ALL of your information, content and data, internally, unless an exception applies, in which case we will advise you accordingly.
                  </li>
                  <li>
                    We will then make a formal request to our service providers to delete and confirm the deletion of the relevant information.
                  </li>
                  <li>
                    We will update you on the progress of Steps #5 and #6.
                  </li>
                  <li>
                    We will confirm via email that your data has been successfully deleted by Us AND by our service providers.
                  </li>
                </ol>
                <p>
                  <i>Right to Rectification</i> - You have the right to request that we update any information about you that is inaccurate or incomplete.
                  Once we receive and confirm your verifiable consumer request, we will update (and direct our service providers to update) your Personal Information in our records, except archive or backup systems. In the alternative, we may provide you with instructions as to how you may correct your Personal Information yourself.
                  We may request documentation supporting that your requested correction is accurate.
                  You also have the right to require us to restrict processing of your Personal Information in certain circumstances, e.g. if you contest the accuracy of the Personal Information.
                </p>
                <p>
                  <u>Steps to exercise your right to update, complete or amend your Personal Information (Data Rectification):</u>
                </p>
                <ol>
                  <li>
                    Contact us using the Apps: Open the app's side-menu and select Help & Support.
                    Next, select Email us; or by emailing us at <Link className="regular" to='#'
                      onClick={(e) => {
                        window.location.href = 'mailto:support@beautiful-essentials.app';
                        e.preventDefault();
                      }} target="_blank">support@beautiful-essentials.app</Link>
                  </li>
                  <li>
                    In your message, please mention “Right to amend my Data” in the message's subject field.
                  </li>
                  <li>
                    We will then confirm the receipt of your message and may ask follow up questions for verification.
                  </li>
                  <li>
                    We will amend or alter your information according to your request and instructions.
                    We may ask you to provide documentation if necessary to rebut our own documentation that the Personal Information is accurate.
                  </li>
                  <li>
                    We will then make a formal request to our service providers to do so as well and to amend or alter your information accordingly.
                  </li>
                  <li>
                    We will update you on the progress of Steps #4 and #5.
                  </li>
                  <li>
                    We will confirm via email that your data has been successfully amended by Us AND by our service providers.
                  </li>
                </ol>
                <p>
                  <i>Right to Restrict Processing, Right to Object</i> - The right to restrict processing allows individuals to temporarily halt the processing of their Personal Information by us under certain circumstances.
                  This right can be exercised when the accuracy of the data is disputed, the processing is unlawful, or you object to processing while awaiting verification of the legitimate grounds for processing.
                  During the restriction period, the data can only be stored, and certain limited operations may be conducted with the data, such as for legal claims or to protect the rights of another person.
                  This provision aims to give individuals more control over their data and protect their interests when concerns or disputes arise regarding the processing of their Personal Information.
                </p>
                <p>
                  You also have a right to object to the processing of your Personal Information for direct marketing purposes, scientific or historical research purposes, or statistical purposes.
                  Where we process your Personal Information solely based on our legitimate interests, you have the Right to Object to us processing your Personal Information, unless (a) our legitimate grounds for the processing override your interests or rights; or (b) required for the establishment, exercise or defense of legal claims.
                  When an objection is raised, we will cease processing your data unless we demonstrate compelling legitimate grounds for the processing or if the processing is necessary for establishment, exercise or defense of legal claims.
                </p>
                <p>
                  If you object to us processing your Personal Information, such as App Usage Patterns, please stop using the Platform in which case we will not collect any new Personal Information and the data collected until then will be stored in accordance with the <a href="#personal-info">“How Long Your Personal Information Will Be Kept”</a> Section of this Policy.
                </p>
                <p>
                  <i>Right to Data Portability</i> - You have the right to receive your Personal Information in format that is structured, commonly used, and machine-readable.
                  This right is designed to enhance your control over your data by allowing you to easily transfer it to other service providers or organizations of your choice.
                </p>
                <p>
                  <u>Steps to exercise your right to get a copy of your Personal Information (Data Portability):</u>
                </p>
                <ol>
                  <li>
                    Contact us using the Apps: Open the app's side-menu and select Help & Support.
                    Next, select Email us; or by emailing us at <Link className="regular" to='#'
                      onClick={(e) => {
                        window.location.href = 'mailto:support@beautiful-essentials.app';
                        e.preventDefault();
                      }} target="_blank">support@beautiful-essentials.app</Link>.
                  </li>
                  <li>
                    In your message, please mention “Right to a Copy of My Data” in the message's subject field.
                  </li>
                  <li>
                    We will then confirm the receipt of your message, and may ask follow up questions for verification.
                  </li>
                  <li>
                    We will assemble ALL of your information, content and data, internally.
                  </li>
                  <li>
                    We will then make a formal request to our service providers to send us or allow us to view your relevant information.
                  </li>
                  <li>
                    We will update you on the progress of Steps #4 and #5.
                  </li>
                  <li>
                    We will confirm via email that your data has been successfully gathered by Us AND by our service providers, and we will then provide you with the information you requested.
                  </li>
                </ol>
                <p>
                  <i>Right to Withdraw Consent</i> - You have the right to withdraw or decline your consent at any time, to the extent that we rely on your consent to process your Personal Information.
                  If you do so, we will stop the processing of your Personal Information for that specific purpose.
                </p>
                <p>
                  <u>Steps to exercise your right to Withdraw Consent</u>: If you want to withdraw your consent with regards to cookies on the website, please use the <span onclick="javascript:openAxeptioCookies()" className="manage-cookies">Cookie management widget</span>.
                </p>
                <p>
                  If you want to withdraw your consent to receive our newsletters and/or emails, click on the unsubscribe button at the bottom of our newsletter or email or contact us in accordance with the <a href="#contact-info">“Contact Information”</a> Section below.
                  In your message, please mention “Unsubscribe” in the message's subject field. Once we received your request, we will stop sending you our newsletters and/or emails.
                </p>
                <p>
                  To exercise your rights, please go to the section labeled <a href="#privacy-rights">“Managing Your Privacy Rights and Choices”</a>.
                </p>
              </li>
              <li className="section" id="section-gc">
                <p className="header-2 titles bold">Cross-Border Data Transfers</p>
                <p>
                  Our operations are supported by a network of computers, cloud-based servers, and other infrastructure and information technology, including, but not limited to, third-party service providers.
                  From time to time, we may transfer Personal Information from the European Economic Area (EEA) to a third country not being approved by European commission as a safe country for such transfer (adequacy decision), including the United States.
                  However, we take additional measures when information is transferred from the EEA.
                  This includes having standard contractual clauses approved by the European Commission in our contracts with parties that receive information outside the EEA.
                  We also rely on European Commission adequacy decisions about certain countries, as applicable, for data transfers to countries outside the EEA.
                  For further information please see the <a href="#territoriality">“Territoriality”</a> Section above.
                </p>
              </li>
            </ol>
          </div>
          <div className="section" id="title-h">
            <p className="header-2 titles bold">NOTICE TO CANADIAN CONSUMERS</p>
            <p>
              This notice should be read in conjunction with the rest of our Privacy Policy.
              This section is intended to inform Canadian Residents of their rights.
              This Privacy Notice applies to Personal Information that We collects or processes about an individual resident of Canada (“Consumer”).
            </p>
            <p>
              For information on what Personal Information we collect and for what purposes, please go to the section labeled <a href="#section-b">“Personal Information We Collect”</a> and <a href="#section-e">“Business and Commercial Purposes of Collecting Personal Information from Consumers”</a>. in our Privacy Policy.
            </p>
            <p>
              For information on what Personal Information we may disclose to third parties, please go to the sections labeled <a href="#section-g">“Disclosure of Personal Information to Our Service Providers”</a> and <a href="#section-f">“Categories of Personal Information We Have Sold Or Shared Including In The Preceding 12 Months”</a>.
            </p>
            <p>
              For more information on cookies, how We uses cookies, what types of cookies we use, and how to manage your cookies, please go to the section labeled <a href="#cookies">“Cookies and Similar Technologies”</a> and our <a href="/cookie-policy" target="_blank">Cookie Policy</a>.
            </p>
            <ol>
              <li className="section" id="section-ha">
                <p className="header-2 titles bold">Canadian Residents' Rights and Choices</p>
                <p>
                  <i>Right to Access</i> - Canadian residents have the right to request a copy of the information we hold about them.
                  Before we provide a substantive response to your request to access your Personal Information, we will verify your identity to make certain you are who you say you are.
                  We will respond within 30 days of receiving your written request.
                </p>
                <p>
                  We will not disclose in response to an access request your account password or biometric identifiers.
                  We will, however, inform you or your representative with sufficient particularity if we have collected that type of information.
                </p>
                <p>
                  <i>Right to Accurate Data</i> - You have the right to request that we update any information about you that is inaccurate or incomplete.
                  Once we receive and confirm your verifiable consumer request, we will update (and direct our service providers to update) your Personal Information in our records, except archive or backup systems.
                  In the alternative, we may provide you with instructions as to how you may correct your Personal Information yourself.
                  We may request documentation supporting that your requested correction is accurate.
                  You also have the right to require us to restrict processing of your Personal Information in certain circumstances, e.g. if you contest the accuracy of the Personal Information.
                </p>
                <p>
                  To exercise your rights, please go to the section labeled <a href="#privacy-rights">“Managing Your Privacy Rights and Choices”</a>.
                </p>
              </li>
            </ol>
          </div>
          <div className="section" id="title-i">
            <p className="header-2 titles bold">NOTICE TO RESIDENTS OF JURISDICTIONS OTHER THAN THE UNITED STATES, CANADA, THE UNITED KINGDOM AND THE EUROPEAN UNION</p>
            <p>
              If you are a resident of a jurisdiction other than the United States, the United Kingdom, Canada or the European Union, please be aware that the laws of your jurisdiction may confer upon you similar or additional rights with respect to your Personal Information compared to the rights outlined in this Privacy Policy.
              To learn more about your specific rights and how to exercise them, we encourage you to contact us at <Link className="regular" to='#'
                onClick={(e) => {
                  window.location.href = 'mailto:support@beautiful-essentials.app';
                  e.preventDefault();
                }} target="_blank">support@beautiful-essentials.app</Link>.
              Your privacy is important to us, and we are dedicated to assisting you in understanding and exercising your privacy rights.
            </p>
          </div>
          <div className="section" id="biometric-data-policy">
            <p className="header-2 titles bold">BIOMETRIC DATA POLICY</p>
            <ol>
              <li>
                <p className="bold">Definitions:</p>
                <ol>
                  <li>
                    As used in this policy, “biometric data” includes both “biometric identifiers” and “biometric information” as defined in the Illinois Biometric Information Privacy Act, 740 ILCS § 14/1 to 14/99, and elsewhere.
                    In addition to Illinois, there are other states with biometric laws, such as Texas and Washington, along with states with comprehensive data privacy laws that address the handling of biometric data or information.
                    This policy is designed to be as broad as possible to cover the allowable uses and restrictions in each of the individual jurisdictions.
                  </li>
                  <li>
                    “Biometric identifier” is defined to include a retina or iris scan, fingerprint, voiceprint, or record of scan of hand or face geometry.
                    However, biometric identifiers do not include writing samples, written signatures, photographs, demographic data, tattoo descriptions, or physical descriptions such as height, weight, hair color, or eye color.
                    Other laws may provide for additional modes of identification and additional exceptions from the definition of “biometric identifier” or “biometric information.”
                  </li>
                  <li>
                    “Biometric information” means any information, regardless of how it is captured, converted, stored, or shared, based on an individual's biometric identifier used to identify an individual.
                    Biometric information does not include information derived from items or procedures excluded under the definition of biometric identifiers.
                  </li>
                </ol>
              </li>
              <li>
                <p className="bold">Purpose for Collection of Biometric Data</p>
                <p>
                  We and/or our service providers pursuant to appropriate data sharing agreements, may collect, store, and use biometric data solely for the purpose of providing you with the Services that you choose to use.
                  To provide some of the features, We use generative AI and models that are trained on content (which may include images or voice recordings) that you provide. Generative AI may process biometric data.
                  We do NOT use biometric data to identify you or any individual.
                </p>
              </li>
              <li>
                <p className="bold">Disclosure</p>
                <p>
                  We will not disclose or disseminate biometric data to anyone other than our service providers that process biometric data on behalf of Us in furtherance of our services to our users unless:
                </p>
                <ol>
                  <li>
                    We obtain your (and/or the legally authorized representative of the individual's whose data is to be processed) consent to such disclosure or dissemination;
                  </li>
                  <li>
                    The disclosed data completes a transaction that you requested or authorized;
                  </li>
                  <li>
                    Disclosure is required by state or federal law or municipal ordinance; or
                  </li>
                  <li>
                    Disclosure is required pursuant to a valid warrant or subpoena issued by a court of competent jurisdiction.
                  </li>
                </ol>
                <p>
                  Service providers who assist us with its processes, including but not limited to the maintenance of servers, disposal or recycling of equipment, shall also be encompassed within our authorized recipients and holders of biometric data provided that a valid data sharing agreement is in place with each such vendor.
                </p>
              </li>
              <li>
                <p className="bold">Data Retention Schedule</p>
                <p>
                  We, and its service providers, retain biometric data only for as long as necessary to provide the Services for which the data is processed, collected, stored, or used.
                  Normally, we delete biometric data, and any generative models created using biometric data, within 30 days.
                  In any event, in the absence of an overriding legal duty to retain the data, biometric data is permanently destroyed when the purpose for collecting the data has been satisfied, and not later than the first anniversary of the date the purpose for collecting data expires, or within three years of your last interaction with the Platform, whichever occurs first.
                </p>
              </li>
              <li>
                <p className="bold">Data Storage</p>
                <p>
                  We shall use reasonable security measures to store, transmit and protect from unauthorized disclosure all biometric data collected under this policy.
                  Such storage, transmission, and protection from disclosure shall be performed in a manner that is the same as or more protective than the manner in which our stores, transmits and protects from disclosure other confidential and sensitive information, including Personal Information, that can be used to uniquely identify an individual or an individual's account or property, such as financial account numbers.
                  We also has internal procedures for responding to security incidents and providing notices if required by law.
                </p>
              </li>
              <li>
                <p className="bold">Consent</p>
                <p>
                  We will collect your biometric data only with your consent.
                  You may withdraw your consent at any time through the App's settings (“Biometric information policy consent”).
                </p>
              </li>
            </ol>
          </div>
          <div className="section" id="privacy-rights">
            <p className="header-2 titles bold">MANAGING YOUR PRIVACY RIGHTS AND CHOICES</p>
            <p>
              <i>Exercising Your Rights</i>
            </p>
            <p>
              To exercise the rights described above, please submit a verifiable consumer request to us by Email: <Link className="regular" to='#'
                onClick={(e) => {
                  window.location.href = 'mailto:support@beautiful-essentials.app';
                  e.preventDefault();
                }} target="_blank">support@beautiful-essentials.app</Link>.
            </p>
            <p>
              Please mention the right you seek to exercise (example, Right to be Forgotten) in the email message's subject field or in the subject of your letter.
              Please also advise us which state or country you are a resident of.
            </p>
            <p>
              You may also submit your request by opening the Apps side-menu and selecting Help & Support; and Email us.
              In your message, please mention the right you seek to exercise (example, Right to be Forgotten) in the message's subject field.
            </p>
            <p>
              You may be limited in the number of verifiable consumer requests for access or data portability in any given 12-month period (for example, twice per 12-month period for California residents).
            </p>
            <p>
              The verifiable consumer request must:
              <ul>
                <li>
                  Provide sufficient information that allows us to reasonably verify you are the person about whom we collected Personal Information or a legally authorized representative.
                </li>
                <li>
                  Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.
                </li>
                <li>
                  Before responding to your privacy rights request, we must verify your identity.
                  We will do so by matching at least two reliable data points from your request to the information that we maintain about you.
                  For certain more sensitive Personal Information, we may require further verification, including obtaining the consumer's signed declaration under penalty of perjury to further confirm that the requestor is the consumer.
                  Once you contact us to exercise your rights, we will further guide you as to our verification process.
                  However, please note that we will not verify your identity where not permitted under privacy laws, such as for opt-out of sale or sharing or limit use and disclosure of Sensitive Personal Information rights under certain privacy laws, such as the California Consumer Privacy Act.
                </li>
              </ul>
            </p>
            <p>
              We cannot respond to your request or provide you with Personal Information if we cannot verify your identity or authority to make the request and confirm the Personal Information relates to you.
              Making a verifiable consumer request does not require you to create an account with us.
              We will only use Personal Information provided in a verifiable consumer request to verify the requestor's identity or authority to make the request.
            </p>
            <p>
              Under certain circumstances, we may deny your request for the reasons permitted by the applicable law, which we will specifically identify for you.
            </p>
            <p>
              <b>Appeal Process:</b> You have the right to appeal our denial of a rights request.
              If you would like to appeal a denial of a rights request, please contact us via email at <Link className="regular" to='#'
                onClick={(e) => {
                  window.location.href = 'mailto:support@beautiful-essentials.app';
                  e.preventDefault();
                }} target="_blank">support@beautiful-essentials.app</Link>.
              We will inform you in writing of any action taken or not taken in response to an appeal not later than the 60th day after the date of receipt of the appeal, including a written explanation of the reason or reasons for the decision.
              If you remain unsatisfied with the resolution of your appeal, you can file a complaint with the Office of the Attorney General in your State of residence (for U.S. residents).
              In our appeal response, we will provide you with the information on how a complaint may be filed with the Office of the Attorney General of your State of residence.
            </p>
            <p>
              If you are a United Kingdom or a European Union resident and remain unhappy with a response you receive from us, you have a right to contact your data protection supervisory authority (see https://ec.europa.eu/newsroom/article29/items/612080 for Europe, or https://ico.org.uk/make-a-complaint for the United Kingdom).
            </p>
            <p>
              <i>Authorized Agents</i>
            </p>
            <p>
              Only you or a person that you or your employer authorize to act on your behalf may make a verifiable consumer request related to your Personal Information.
              You may also make a verifiable consumer request on behalf of your minor child.
              You may use an authorized agent to submit a privacy rights request on your behalf if you provide the authorized agent a written permission signed by you or give the authorized agent power of attorney.
              We may also require you to do either of the following, unless the authorized agent provides power of attorney documentation:
            </p>
            <ol>
              <li>
                Verify your own identity directly with us.
              </li>
              <li>
                Directly confirm with us that you provided the authorized agent permission to submit the request.
              </li>
            </ol>
            <p>
              We may deny a request from an authorized agent if the agent cannot or does not provide to us your signed permission demonstrating that they have been authorized by you to act on your behalf or if you do not confirm to us that you provided the authorized agent permission to submit the request.
            </p>
            <p>
              The requirement to obtain and provide written permission from the consumer does not apply to requests made by an opt-out preference signal.
            </p>
            <p>
              <i>Response Timing and Format</i>
            </p>
            <p>
              We will acknowledge your request no later than ten (10) business days after receiving your request to delete, request to correct, or request to know and provide information about how we will process the request.
              The information provided shall describe in general our verification process and when you should expect a response, except in instances where we have already granted or denied the request.
            </p>
            <p>
              We shall respond to a verifiable consumer request within 45 calendar days of its receipt.
              If you are a European Resident, we will respond within 30 days of receiving your written request.
              If we require more time (up to 90 days, unless a different time period is allowed by law), we will inform you of the reason and extension period in writing.
              We will deliver our written response by email.
              For California residents, any disclosures we provide will only cover the 12-month period preceding the verifiable consumer request's receipt, unless you request us to provide Personal Information beyond the 12-month period, as long as it was collected on or after January 1, 2022.
              The response we provide will also explain the reasons we cannot comply with a request, if applicable.
              For data portability requests, we will select a format to provide your Personal Information that is readily useable and should allow you to transmit the information from one entity to another entity without hindrance.
            </p>
            <p>
              We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded.
              If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request.
              For example, in cases where requests from a consumer are distinctly unsubstantiated or excessive, in particular because of their repetitive character, we may either charge a reasonable fee, in our discretion, taking into account the administrative costs of providing the information or communication or taking the action requested; or refuse to act on the request altogether.
            </p>
          </div>
          <div className="section" id="title-l">
            <p className="header-2 titles bold">GLOBAL OPT OUT PREFERENCE SIGNAL</p>
            <p>
              We honor opt-out preference signals. An opt-out preference signal is a signal that is sent by a platform, technology or mechanism on your behalf that communicates your choice to opt-out of the sharing for targeted advertisements or sale of your personal information.
              You can learn more about implementing opt-out preference signals <Link to={'https://globalprivacycontrol.org/'} target='_blank'>here</Link> or by exploring other developing technologies and services that offer this tool.
            </p>
            <p>
              We treat opt-out preference signals as valid requests to opt-out of the sale or sharing of your personal information under privacy laws. Ordinarily, your opt- out preference signal is sufficient for us to honor your request, but in some instances we may request additional information to help facilitate your request for offline sales or sharing.
              We will not use, disclose or retain this additional information for any purpose other than to facilitate the request.
              However, while we honor opt-out preference signals, we do not respond to “Do Not Track” requests because there is not yet a common understanding of how to interpret such signals.
            </p>
          </div>
          <div className="section" id="title-m">
            <p className="header-2 titles bold">KEEPING YOUR PERSONAL INFORMATION SECURE</p>
            <p>
              We have reasonable technical, physical and administrative security measures in place to prevent Personal Information from being accidentally lost or used or accessed in an unauthorized way.
              These measures offer appropriate reasonable security level, taking into account the state of the technology, the implementation costs and also the nature, the scale, the context and the processing purposes.
              We limit access to your Personal Information to those who have a genuine business need to access it.
              Those processing your information will do so only in an authorized manner and are subject to a duty of confidentiality.
              The measures are also intended to prevent unnecessary collection and further processing of Personal Information.
              The Personal Information is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems and are required to keep the information confidential.
              We also have procedures in place to deal with any suspected data security breach. We will notify you and any applicable regulator of a suspected data security breach where we are legally required to do so.
            </p>
          </div>
          <div className="section" id="title-n">
            <p className="header-2 titles bold">LINKS TO THIRD PARTY WEBSITES</p>
            <p>
              Links to other sites and applications may be provided by us as a convenience to our users.
              We are not responsible for the privacy practices, or the content of other sites and applications and you visit them at your own risk.
              We recommend you to carefully read the terms of use and privacy policies of such sites and applications.
              This Privacy Policy applies solely to Personal Information collected by us.
            </p>
          </div>
          <div className="section">
            <p className="header-2 titles bold">DISCLAIMER/LIMITATION OF LIABILITY FOR DATA INPUT ERRORS</p>
            <p>
              We are not responsible for any errors that are made by you in inputting data, nor for data entered by someone using your credentials.
              PLEASE SECURE YOUR CREDENTIALS AND DO NOT DISCLOSE THEM TO ANYONE ELSE.
              If your credentials are compromised, please notify us at <Link className="regular" to='#'
                onClick={(e) => {
                  window.location.href = 'mailto:support@beautiful-essentials.app';
                  e.preventDefault();
                }} target="_blank">support@beautiful-essentials.app</Link>.
            </p>
            <p>
              Under no circumstances shall we be liable to you, including but not limited to any liability for the system not being available for use, for lost or corrupted data, for errors in the documentation provided to you, if any, or for the failure of data archiving.
              Please keep track of your own time in case of a system malfunction. Except as expressly agreed with our client, we will not be liable for any consequential, special, indirect, or punitive damages, even if advised of the possibility of such damages, or for any claim by any third party.
            </p>
          </div>
          <div className="section" id="title-o">
            <p className="header-2 titles bold">CHANGES TO OUR PRIVACY POLICY</p>
            <p>
              We reserve the right to amend this Policy at our discretion and at any time. When we make significant changes to this Policy, we will notify you by email or through a notice on our websites' homepages and/or our Apps.
              You will also be able to determine the date that our current Policy has become effective, by viewing the Effective Date at the top of this document.
            </p>
          </div>
          <div className="section" id="contact-info">
            <p className="header-2 titles bold">CONTACT INFORMATION</p>
            <p>
              Our privacy program is managed by our legal team and in-house privacy officer.
              We regularly review its compliance with this Privacy Policy.
              Please feel free to direct any questions or concerns regarding this Privacy Policy or our treatment of Personal Information by contacting us at the addresses below.
              When we receive formal written complaints, it is our policy to contact the complaining user regarding his or her concerns.
              We will cooperate with the appropriate regulatory authorities, including local data protection authorities, to resolve any complaints regarding the transfer of Personal Information that cannot be resolved between us and an individual.
            </p>
            <p>
              If you're based in the European Economic Area and think that we haven't complied with data protection laws, you have a right to lodge a complaint with the Data Protection Commission in Hamburg, Germany or with your local supervisory authority.
            </p>
            <p>
              If you have any questions or comments about this Policy, the ways in which we collect and use your Personal Information, your choices and rights regarding such use, or wish to exercise your rights under this Policy, please do not hesitate to contact us at:
            </p>
            <p>
              Email: <Link className="regular" to='#'
                onClick={(e) => {
                  window.location.href = 'mailto:support@beautiful-essentials.app';
                  e.preventDefault();
                }} target="_blank">support@beautiful-essentials.app</Link>
            </p>
            <p>
              <u className="bold">EU Representative:</u>
            </p>
            <p>
              Email: <Link className="regular" to='#'
                onClick={(e) => {
                  window.location.href = 'mailto:photomyne@gdpr-rep.com';
                  e.preventDefault();
                }} target="_blank">photomyne@gdpr-rep.com</Link>
              <br></br>
              Postal Address: DP-Dock GmbH
              <br></br>
              Attn: Photomyne,
              <br></br>
              Ballindamm 39
              <br></br>
              20095 Hamburg, Germany
            </p>
            <p>
              <u className="bold">UK Representative:</u>
            </p>
            <p>
              Email: <Link className="regular" to='#'
                onClick={(e) => {
                  window.location.href = 'mailto:photomyne@gdpr-rep.com';
                  e.preventDefault();
                }} target="_blank">photomyne@gdpr-rep.com</Link>
              <br></br>
              DP Data Protection Services UK Ltd.
              <br></br>
              Attn: Photomyne
              <br></br>
              16 Great Queen Street
              <br></br>
              Covent Garden, London, WC2B 5AH, United Kingdom
            </p>
          </div>
          <div className="regular section updated-on">
            Last updated: October 27, 2024
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPage;
