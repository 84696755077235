import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';

function AppBanners(props) {  
  return (
    <div className='banners-contanier layout-column center-center lg-only'>
      <p className="title header-2 bold white icons-title layout-row">Featured apps</p>
      <div className='banner layout-row center-left'>
      {props.BannerData1.map((app, i) => (
              <Link key={i} className='app-banner' to={app.link} target="_blank"
              onClick={(e) => {
                e.stopPropagation();
                props.eventLog({'ga': 'download', 'fb': 'ViewContent', 'tt': 'ClickToDownload'}, app.name.replaceAll(' ', '_').toLowerCase());
              }}>
                <img alt='' src={app.image}/>
              </Link>
        ))}
      </div>
      <div className='banner layout-row center-left'>
      {props.BannerData2.map((app, i) => (
              <Link key={i} className='app-banner' to={app.link} target="_blank"
              onClick={(e) => {
                e.stopPropagation();
                props.eventLog({'ga': 'download', 'fb': 'ViewContent', 'tt': 'ClickToDownload'}, app.name.replaceAll(' ', '_').toLowerCase());
              }}>
                <img alt='' src={app.image}/>
              </Link>
        ))}
      </div>
    </div>
  );
}

export default AppBanners;
