import '../App.css';
import { Link } from 'react-router-dom';

function Footer(props) {
  return (
    <div className="Footer">
      <div className="footer layout-column center-center">
        <div className="footer-links layout-row center-center">
          <Link className="remark white" to='#'
            onClick={(e) => {
                window.location.href = 'mailto:support@beautiful-essentials.app';
                e.preventDefault();
            }} target="_blank">Support</Link>
          <p className="remark white line-separator">|</p>
          <Link className="remark white" to="/privacy-policy" target="_blank">Privacy Policy</Link>
          <p className="remark white line-separator">|</p>
          <Link className="remark white" to="/terms-of-use" target="_blank">Terms of use</Link>
          <p className="remark white line-separator">|</p>
          <p className="remark white ccpa-opt-out">Do not sell</p>
          <p className="remark white line-separator">|</p>
          <Link className="remark white" to="/accessibility-statement" target="_blank">Accessibility Statement</Link>
          <p className="remark white line-separator">|</p>
          <Link className="remark white" to="/cookie-policy" target="_blank">Cookie Policy</Link>
          <p className="remark white line-separator">|</p>
          <Link className="remark white" to='#'
            onClick={(e) => {
                window.location.href = 'mailto:support@beautiful-essentials.app?subject=Beautiful Essentials - Please delete my account&body=Please fill in the gmail address you signed in with and wish to delete.';
                e.preventDefault();
            }} target="_blank">Delete Account</Link>
          <p className="remark white line-separator">|</p>
          <a href="javascript:openAxeptioCookies()" className="remark white manage-cookies"
          //   onClick={(e) => {
          //     e.stopPropagation();
          //     e.preventDefault();
          //     props.setShowCookies(true);
          // }}
          >Cookie Settings</a>
          <p className="remark white line-separator">|</p>
          <p className="remark white">(v 3.1)</p>
        </div>
        <p className="remark white copy-right">&copy; 2024 Beautiful Essentials</p>
      </div>
    </div>
  );
}

export default Footer;
